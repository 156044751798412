@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  @font-face {
    font-family: 'Lora Variable';
    src: url('../fonts/Lora/Lora-VariableFont_wght.ttf') format('truetype');
    font-weight: 100 900;
    font-style: normal;
  }

  @font-face {
    font-family: 'Lora Variable';
    src: url('../fonts/Lora/Lora-Italic-VariableFont_wght.ttf')
      format('truetype');
    font-weight: 100 900;
    font-style: italic;
  }

  @font-face {
    font-family: 'Libre Franklin Variable';
    src: url('../fonts/LibreFranklin/LibreFranklin-VariableFont_wght.ttf')
      format('truetype');
    font-weight: 100 900;
    font-style: normal;
  }

  @font-face {
    font-family: 'Libre Franklin Variable';
    src: url('../fonts/LibreFranklin/LibreFranklin-Italic-VariableFont_wght.ttf')
      format('truetype');
    font-weight: 100 900;
    font-style: italic;
  }
}
/* important used here to override react bootstrap */
body {
  margin: 0;
  font-family:
    'Lora Variable',
    'Libre Franklin Variable',
    -apple-system,
    BlinkMacSystemFont,
    'Segoe UI',
    'Roboto',
    'Oxygen',
    'Ubuntu',
    'Cantarell',
    'Fira Sans',
    'Droid Sans',
    'Helvetica Neue',
    sans-serif !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family:
    source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

.grayscale {
  filter: grayscale(1);
}

#root {
  background-color: white;
}

/* temporary overrides of react bootstrap */
p {
  margin-bottom: unset !important;
}
